<template>
    <el-dialog
    title="邀请成员"
    :close-on-click-modal="false" 
    :visible.sync="dialogVisible" @close="canclesClick">
        <!-- <el-input v-model="formInline.name" placeholder="请输入手机号/用户名/登录名" @keyup.native.enter="getKeyUpEvent($event)">
            <i slot="suffix" class="el-input__icon el-icon-search seicons" @click="handleClick"></i>
        </el-input>
        <ul class="search-list" v-if="searchResult">
            <li v-for="(item,index) in searchList" :key="index" :class="{'active': index == ind}" @click="tabLiEvent(index,item)">
                {{item.name}}
            </li>
        </ul> -->
        <el-form :inline="true" ref="form" :model="formInline" :rules="rules" label-width="110px">
            <el-form-item label="手机号/用户名" prop="name">
                <el-select
                    v-model="formInline.name"
                    filterable
                    remote
                    clearable
                    reserve-keyword
                    placeholder="请输入手机号/用户名/登录名"
                    :remote-method="querySearchAsync"
                    @clear="clearSearchUserEvent"
                    :loading="loading">
                    <el-option
                        v-for="selectItems in searchList"
                        :key="selectItems.id"
                        :label="selectItems.name"
                        :value="selectItems.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="密级" prop="selectValue">
                <el-select v-model="formInline.selectValue" placeholder="请选择密集" @change="handleChangeMiji">
                    <el-option
                    v-for="item in miji"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
        </el-form>

        <span slot="footer" class="dialog-footer">
            <el-button size="small" @click="canclesClick">取 消</el-button>
            <el-button size="small" type="primary" @click="sureEvent" :loading="sendLoading">发送邀请</el-button>
        </span>
    </el-dialog>
</template>
<script>
import { usermanagerAPI, orgAPI } from 'liankong-ui-api' 

export default {
    data() {
        return {
            ind: undefined,
            dialogVisible: false,
            formInline: {
                name: '',
                phoneNumber: '',
                selectValue: '', // 密集
                pageSize: 30
            },
            rules: {
                name: [
                    { required: true, message: '手机号/用户名不能为空'}
                ],
                selectValue: [
                    { required: true, message: '密级不能为空', trigger: 'change'}
                ]
            },
            isVisible: false,
            searchList: [], // 搜索结果内容
            itemInfo: {},
            orgInfos: {}, // 部门添加成员的信息
            variable: '',
            getLists: {}, // 组织添加成员的信息
            loading: false,
            sendLoading: false
        };
    },
    computed: {
        // names(){
        //     return this.formInline.name
        // },
        // // 是否搜索结果
        // searchResult(){
        //     return this.formInline.name && this.searchList.length > 0
        // },
        miji(){
            return this.$store.state.dict.dicts.miji
        },
    },
    watch: {
        // 输入变动
        // names(value){
        //     if(value && value.length > 0){
        //         this.getSendList()
        //     }else{
        //         this.searchList = []
        //     }
        // },
        // searchResult(val){
        //     if(val && this.ind!=undefined) {
        //         this.ind = undefined
        //     }
        // }
    },
    methods: {
        init(orgInfo, variable) {
            this.variable = variable;
            if(variable == 'deps') {
                this.orgInfos = orgInfo;
            } else if(variable == 'orgs' || variable == 'deporgs') {
                this.getLists = orgInfo;
            }
            this.formInline.selectValue = ''
        },
        // // 键盘事件
        // getKeyUpEvent(event){
        //     if(this.formInline.name) {
        //         if(event.keyCode == 13) { // 回车
        //            this.getSendList()
        //         }
        //     }
        // },
        //关键词联想查询
        querySearchAsync(query) {
            if(query != '') {
                this.formInline.name = query;
                this.formInline.phoneNumber = query;
                this.getSendList();
            } else {
                this.searchList = []
            }
        },
        // 清空
        clearSearchUserEvent(){
            this.formInline.name = ''
            this.searchList = []
        },
        getSendList(){
            usermanagerAPI.getUserList(this.formInline).then(res=>{
                if(res.code == '0') {
                    this.searchList = res.data.records;

                    if(this.searchList.length == 0) {
                        this.$message.error('未搜索到用户');
                    }

                } else {
                    this.$message.error(res.message);
                }
            }).catch(error=>{
                this.$message.error(error.message);
            })
        },
        // 搜索按钮
        // handleClick(){
        //     if(this.formInline.name == '') {
        //         this.$message.error('请输入关键字');
        //         return false;
        //     }
        //     this.getSendList()
        // },
        // 列表点击
        // tabLiEvent(index,item) {
        //     this.ind = index;
        //     this.itemInfo = item;
        // },
        // 确定
        sureEvent(){
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    if(this.variable == 'orgs' || this.variable == 'deporgs') {
                        this.sendLoading = true;
                        let params = {
                            "id": this.getLists.id, // 组织id
                            "miji": this.formInline.selectValue,
                            "userId": this.formInline.name
                        }
                        orgAPI.inviteUser(params).then(res=>{
                            if(res.code == 0) {
                                this.$message.success('已发送邀请');
                                this.canclesClick();
                            } else {
                                this.$message.error(res.message);
                                this.canclesClick();
                            }
                        }).catch(error=>{
                            this.$message.error(error.message);
                            this.canclesClick();
                        })
                    }
                }
            });
        },
        // 取消
        canclesClick(){
            this.dialogVisible = false;
            this.isVisible = false;
            this.formInline = {
                name: '',
                phoneNumber: '',
                selectValue: '',
                pageSize: 30
            }
            this.searchList = [];
            this.sendLoading = false;
            this.$refs['form'].resetFields();
        },
        // 密级 强制渲染
        handleChangeMiji() {
            this.$forceUpdate();
        }
    }
};
</script>
<style lang="less" scoped>
.search-list {
    height: 193px;
    overflow: auto;
    margin-top: 20px;
    border: 1px solid #f2f2f2;
    border-radius: 5px;
    padding: 0;
    cursor: pointer;
    li{
        list-style: none;
        padding: 15px 20px;
    }
    li:hover {
        background: #f2f2f2;
    }
    .active {
        background: skyblue;
    }
    &::-webkit-scrollbar {
        display: none;
    }
}
.seicons {
    cursor: pointer;
    font-size: 20px;
    padding-right: 10px;
}
</style>