<template>
    <div>
        <el-row :gutter="20">
            <!-- <el-col :span="6" class="structure-tree">
                <div class="trees-head">
                    <h4>组织管理</h4>
                    <div style="display:flex;">
                        <el-button type="text" icon="el-icon-coordinate" title="申请加入" @click="applyAddOrgBtn"></el-button>
                        <el-upload
                            class="upload-demo"
                            style="margin: 0 10px;"
                            action="none"
                            accept=".xls,.xlsx"
                            :auto-upload="false"
                            :on-change="onUploadChanged"
                            :show-file-list="false">
                            <el-button type="text" icon="el-icon-upload" title="导入通讯录"></el-button>
                        </el-upload>
                        <el-button type="text" icon="el-icon-download" title="导出" @click="exportOrgBtn"></el-button>
                    </div>
                </div>
                <ul class="list">
                    <li v-for="(item,index) in treeData" :key="index">
                        <div :class="{'active': item.name == activeName}" @click.stop="handleClickOrgTreeLeft(item)">
                            <span :style="{'background': item.colors}">{{item.name.substr(0,1)}}</span>
                            <span>{{item.name}}</span>
                        </div>
                        <div class="item-child" v-if="item.groupModelList">
                            <p v-for="(child, i) in item.groupModelList" :key="i" 
                            :style="{'background': child.name == activeName ? '#F2F8FF' : '', 'color' :child.name == activeName ? '#409EFF' : ''}" @click="handleClickDepTreeLeft(child)">
                                {{child.name}}
                            </p>
                        </div>
                    </li>
                </ul>
            </el-col> -->
            <el-col :span="24">
                <el-table
                    v-if='orgUserViewer.length != 0'
                    :data="orgUserViewer"
                    size="small"
                    style="width: 100%;">
                    <el-table-column
                    type="index"
                    label="序号"
                    align="center"
                    width="150">
                    </el-table-column>
                    <el-table-column
                    prop="username"
                    label="用户名"
                    align="center">
                    </el-table-column>
                    <el-table-column
                    prop="mobile"
                    label="手机号"
                    align="center">
                    </el-table-column>
                    <el-table-column
                    label="组织身份"
                    align="center"
                    v-if="activeInfo.name != '未分配部门'">
                        <template slot-scope="scope">
                            <span>{{scope.row.userId == activeInfo.owner ? '组织管理者' : '成员'}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                    align="center"
                    label="创建时间">
                        <template slot-scope="scope">
                            {{scope.row.joinDate |date}}
                        </template>
                    </el-table-column>
                    <el-table-column
                    label="操作"
                    align="center"
                    v-if="activeInfo.name != '未分配部门'">
                        <template slot-scope="scope">
                            <el-button @click="handleRemoveClick(scope.row)" type="text" size="small" v-if="activeInfo.owner == $store.state.login.id">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>

                 <div class="no-data-box" v-if='orgUserViewer.length == 0'>
                    <img src="@/assets/no-file.svg" alt="">
                    <span>暂无数据</span>
                </div>
                <el-pagination
                 v-if='orgUserViewer.length != 0'
                class="pagebox"
                @current-change="handleCurrentChange"
                :current-page="forLine.pageNum"
                :page-size="forLine.pageSize"
                layout="total,  prev, pager, next, jumper"
                :total="total">
                </el-pagination>

            </el-col>
        </el-row>

        <ApplyorgDialog ref="applyOrgDialog"></ApplyorgDialog>
        <AddteamDialog ref="addTeamDialog" @refreshLeftMenu="getOrgTreeList()" @refreshLeftMenuData="getOrgTreeList()"></AddteamDialog>
        <AdddepDialog ref="addDepDialog" @refreshLeftMenuDatas="getOrgTreeList()"></AdddepDialog>
        <AdduserDialog ref="addUserDialog" @onLoadNOrgList="getNoDepList" @onLoadList="getMemberInit"></AdduserDialog>
        <InvitationMember ref="invitationDialogs"></InvitationMember>
    </div>
</template>
<script>
import { orgAPI } from 'liankong-ui-api'
import ApplyorgDialog from './components/ApplyorgDialog.vue' // 申请加入
import AddteamDialog from './components/AddTeamDialog.vue' // 团队
import AdddepDialog from './components/AddDepDialog.vue' // 部门
import AdduserDialog from './components/AddUserDialog.vue' // 成员
import InvitationMember from './components/InvitationMember.vue' // 邀请成员

export default {
    data() {
        return {
            filterText: '',
            treeData: [], // 组织数据
            activeName: '', // 选中名称
            activeInfo: {}, // 选中数据
            colorArray: ['#C7CBE1', '#D8C5B9', '#E4A88A', '#7FCB97', '#DBA9E0', '#C28DAC', '#F58686', '#EBEA95', '#69A07F', '#E43434'], // 颜色
            viewerKey:'member',
            orgUserViewer: [], // 表格数据
            total: 0,
            forLine: {
                name: '',
                pageNum: 1,
                pageSize: 10
            },
        };
    },
    components: {
        ApplyorgDialog,
        AddteamDialog,
        AdddepDialog,
        AdduserDialog,
        InvitationMember
    },
    inject: ['refreshLeftMyOrg', 'withdrawFromOrg'],
    watch: { },
    mounted(){
        this.$ebus.$on('handleClickOrgTreeLeft', ({data, newTreePostData})=>{
            this.activeInfo = data;
            this.forLine.name = '';

            if(this.activeInfo.organizationId == undefined && this.activeInfo.id) {
                this.getNoDepList()
            } else if(this.activeInfo.organizationId && this.activeInfo.name != '未分配部门'){
                this.getMemberInit();
            } else { // 未分配部门成员
                this.getNoFenDepMenberList()
            }
        })
        // 添加组织/重命名组织
        this.$ebus.$on('addTeamBtn', flag=>{
            if(this.activeInfo.organizationId == undefined && (flag == false || flag)) {
                this.addTeamBtn(flag)
            } else {
                this.$message.error('当前选中的是部门，不能创建组织、对组织重命名')
            }
        })
        // 删除组织
        this.$ebus.$on('removeTeamBtn', val=>{
            if(this.activeInfo.organizationId == undefined) {
                this.deleteOrgEvent()
            } else {
                // this.deleteDepEvent()
                this.$message.error('当前选中的是部门，不能删除组织')
            }
        })
        // 添加、修改部门
        this.$ebus.$on('addDepEvent', flag=>{
            this.addDepEvent(flag)
        })
        // 删除部门
        this.$ebus.$on('removeDepBtn', val=>{
            this.deleteDepEvent()
        })
        // 申请加入
        this.$ebus.$on('applyAddOrgBtn',val=>{
            this.applyAddOrgBtn()
        })
        // 导出
        this.$ebus.$on('exportOrgBtn',val=>{
            this.exportOrgBtn()
        })
        this.$ebus.$on('onUploadChanged', ({file, list})=>{
            this.onUploadChanged(file,list)
        })
        this.$ebus.$on('handleNewMemberEvents', val=>{
            this.handleNewMemberEvents()
        })
        this.$ebus.$on('handleQrcodeEvent', val=>{
            this.handleQrcodeEvent()
        })
        // 搜索
        this.$ebus.$on('handleAuditSearchBtn', formInline=>{
            this.forLine.name = formInline.memberName;

            if(this.activeInfo.organizationId == undefined && this.activeInfo.id) {
                this.getNoDepList()
            } else if(this.activeInfo.organizationId && this.activeInfo.name != '未分配部门'){
                this.getMemberInit();
            } else {
                this.getNoFenDepMenberList()
            }

        })
        this.$ebus.$on('disableOrgBtn', val=>{
            this.disableOrgBtn()
        })
        // 退出组织
        this.$ebus.$on('handleWithdrawFrom', val=>{
            this.handleWithdrawFrom()
        })
    },
    methods: {
        // 刷新组织树
        getOrgTreeList() {
            this.refreshLeftMyOrg()
        },
        // 获取组织下的成员
        getNoDepList(){
            let params = {
                id: this.activeInfo.id,
                name: this.forLine.name,
                pageSize: this.forLine.pageSize,
                pageNum:  this.forLine.pageNum
            }
            orgAPI.getOrgMemberList(params).then(res=>{
                if(res.code == '0') {
                    this.orgUserViewer = res.data;
                    this.total = res.total;
                } else {
                    this.$message.error(res.message);
                }
            }).catch(error=>{
                this.$message.error(error.message);
            })
        },
        // 获取部门下 用户/成员列表
        getMemberInit(){
            this.forLine.pageNum = 1;
            let params = {
                id: this.activeInfo.id,
                name: this.forLine.name,
                pageSize: this.forLine.pageSize,
                pageNum:  this.forLine.pageNum
            }
            orgAPI.getGroupPeopleList(params).then(res=>{
                if(res.code == '0') {
                    this.orgUserViewer = res.data;
                    this.total = res.total;
                } else {
                    this.$message.error(res.message);
                }
            }).catch(error=>{
                this.$message.error(error.message);
            })
        },
        // 获取未分配部门的成员
        getNoFenDepMenberList(){
            let params = {
                id: this.activeInfo.organizationId,
                name: this.forLine.name,
                pageSize: this.forLine.pageSize,
                pageNum:  this.forLine.pageNum
            }
            orgAPI.noGroupedMember(params).then(res=>{
                if(res.code == '0') {
                    this.orgUserViewer = res.data;
                    this.total = res.total;
                } else {
                    this.$message.error(res.message);
                }
            }).catch(error=>{
                this.$message.error(error.message);
            })
        },
        // 分页
        handleCurrentChange(num) {
            this.forLine.pageNum = num;

            if(this.activeInfo.organizationId == undefined && this.activeInfo.id) {
                this.getNoDepList()
            } else if(this.activeInfo.organizationId && this.activeInfo.name != '未分配部门'){
                this.getMemberInit();
            } else {
                this.getNoFenDepMenberList()
            }
        },
        // 添加成员
        handleNewMemberEvents(){
            this.$refs.addUserDialog.dialogVisible = true;
            if(this.activeInfo.organizationId == undefined) {
                this.$nextTick(()=>{
                    this.$refs.addUserDialog.init(this.activeInfo,'deporgs')
                })
            } else {
                this.$nextTick(()=>{
                    this.$refs.addUserDialog.init(this.activeInfo,'deps')
                })
            }
        },
        // 邀请成员
        handleQrcodeEvent(){
            this.$refs.invitationDialogs.dialogVisible = true;
            this.$nextTick(()=>{
                this.$refs.invitationDialogs.init(this.activeInfo,'deporgs')
            })
        },
        // 删除成员
        handleRemoveClick(row){
             if(this.activeInfo.organizationId == undefined && this.activeInfo.id) {
                this.$store.dispatch('newGroup/removeOrgMember',{
                    vkey: this.viewerKey,
                    query:{
                        id: this.activeInfo.id,
                        userId: row.userId
                    }
                }).then(state => {
                    if(state.code==0){
                        this.$message.success("删除成功")
                        this.forLine.pageNum = 1;
                        this.getNoDepList();
                    }else{
                        this.$message.error(state.message)
                    }
                }).catch(state => {
                    this.$message.error(state.message)
                })
            } else if(this.activeInfo.organizationId && this.activeInfo.name != '未分配部门'){
                this.$store.dispatch('newGroup/removeGroupMember',{
                    vkey: this.viewerKey,
                    query:{
                        id: this.activeInfo.id,
                        userId: row.userId
                    }
                }).then(state => {
                    if(state.code==0){
                        this.$message.success("删除成功")
                        this.forLine.pageNum = 1;
                        
                        this.getMemberInit();
                    }else{
                        this.$message.error(state.message)
                    }
                }).catch(state => {
                    this.$message.error(state.message)
                })
            }
           
        },
        // 申请加入
        applyAddOrgBtn() {
            this.$refs.applyOrgDialog.dialogVisibleShow = true;

            this.$nextTick(()=>{
                this.$refs.applyOrgDialog.onInit()
            })
        },
        // 添加、重命名组织
        addTeamBtn(flag) {
            this.$refs.addTeamDialog.dialogVisible = true;
            this.$nextTick(()=>{
                this.$refs.addTeamDialog.init(flag, this.activeInfo);
            })
        },
        // 删除组织
        deleteOrgEvent(){
            this.$store.dispatch('newOrg/deletaOrganization', this.activeInfo.id).then(state=>{
                if(state.code==0){            
                    this.$message.success('删除成功');
                    // 刷新左侧列表
                    this.getOrgTreeList();
                }else{
                    this.$message.error(state.message);
                }
            }).catch(error => {
                this.$message.error(error.message);
            })
        },
        // 添加、修改部门
        addDepEvent(flag){
            this.$refs.addDepDialog.depIsShow = true;
            this.$nextTick(()=>{
                this.$refs.addDepDialog.init(flag, this.activeInfo)
            })
        },
        // 删除部门
        deleteDepEvent(){
            if(this.activeInfo.name=='未分配部门'){
                this.$message.warning('未分配部门不能进行删除！')
                return
            }
            this.$store.dispatch('newGroup/remove', {
                vkey: 'group',
                query: {
                    id: this.activeInfo.id
                }
            }).then(state => {
                if(state.code==0){
                    this.$message.success("删除成功");
                    // 刷新左侧列表
                    this.getOrgTreeList();
                }else{
                    this.$message.error(state.message)
                }
            }).catch(state => {
                    this.$message.error(state.message)
            })
        },
        // 上传
        onUploadChanged(file, list){
            //获取中文件后缀
            const fileSuffix = file.name.substring(file.name.lastIndexOf(".") + 1);
            const whiteList = ['xls','xlsx'];
            if (whiteList.indexOf(fileSuffix) === -1) {
                this.$message.error('上传文件只能是 xls、xlsx格式');
                return false;
            }

            var uploadFormData = new FormData()
            uploadFormData.append("file", file.raw)
            let type = this.activeInfo.hasOwnProperty('organizationId')?1:0
            let orgId = this.activeInfo.id
            uploadFormData.append("type", type)
            uploadFormData.append("orgId", orgId)
            this.$store.dispatch('newOrg/exportUploadOrg', uploadFormData).then(res=>{
                if(res.code == 0) {
                    this.$message.success('导入成功');
                    this.getOrgTreeList();
                } else {
                    this.$message.error(res.message);
                }
            }).catch(error=>{
                this.$message.error(error.message);
            })
        },
        // 导出
        exportOrgBtn(){
            this.$store.dispatch('newOrg/exportOrgInfo')
        },
        // 解散组织
        disableOrgBtn() {
            this.$store.dispatch('newOrg/deletaOrganization', this.activeInfo.id).then(state=>{
                if(state.code==0){            
                    this.$message.success('解散成功');
                    // 刷新左侧列表
                    this.getOrgTreeList();
                }else{
                    this.$message.error(state.message);
                }
            }).catch(error => {
                this.$message.error(error.message);
            })
        },
        // 退出组织
        handleWithdrawFrom() {
            this.$confirm('是否确定退出当前组织?','提示',{
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(()=>{
                this.$store.dispatch('newOrg/outOrgEvent', this.activeInfo.id).then(res=>{
                    if(res.code == 0) {
                        this.$message.success('退出成功');
                        // 刷新左侧列表
                        this.exitOrg();
                    } else {
                        this.$message.error(res.message);
                    }
                }).catch(error => {
                    this.$message.error(error.message);
                })
            }).catch(()=>{

            })
            
        },
        exitOrg() {
            this.withdrawFromOrg()
        }
    },
    beforeDestroy() {
        this.$ebus.$off('handleClickOrgTreeLeft');
        this.$ebus.$off('addTeamBtn');
        this.$ebus.$off('removeTeamBtn');
        this.$ebus.$off('addDepEvent');
        this.$ebus.$off('removeDepBtn');
        this.$ebus.$off('applyAddOrgBtn');
        this.$ebus.$off('exportOrgBtn');
        this.$ebus.$off('onUploadChanged');
        this.$ebus.$off('handleNewMemberEvents');
        this.$ebus.$off('handleQrcodeEvent');
        this.$ebus.$off('handleAuditSearchBtn');
        this.$ebus.$off('disableOrgBtn');
        this.$ebus.$off('handleWithdrawFrom');
    }
};
</script>
<style lang="less" scoped>
.structure-tree {
    .trees-head {
        display: flex;
        justify-content: space-between;
        height: 40px;
        line-height: 40px;
        h4 {
            margin: 0;
            padding: 0;
        }
        /deep/ .el-button {
            font-size: 18px;
            padding: 10px 0px;
            .el-icon-plus {
                font-weight: bolder;
            }
        }
    }
    .list {
        margin: 10px 0 0 0;
        padding: 0;
        li {
            list-style: none;
            margin-top: 10px;
            cursor: pointer;
            div:nth-child(1) {
                padding: 5px 10px;
                span:nth-child(1) {
                    display: inline-block;
                    width: 40px;
                    height: 40px;
                    line-height: 40px;
                    border-radius: 50%;
                    background: #C7CBE1;
                    text-align: center;
                    margin-right: 10px;
                    color: #fff;
                }
            }
            .item-child {
                width: 100%;
                p {
                    padding: 0;
                    margin: 0;
                    padding-left: 65px;
                    height: 45px;
                    line-height: 45px;
                }
            }
            .active {
                background: #F2F8FF;
                span:nth-child(2) {
                    color: #409EFF;
                }
            }
        }
    }
}
/deep/ .el-table {
    .el-table__header-wrapper {
        .has-gutter {
            tr th{
                border:none;
                background: #F2F2F2;
                color: #666;
            }
        }
    }
    .el-table__body-wrapper {
        tr td {
            border:none;
            padding: 12px 0;
        }
    }
    &::before {
        height: 0px;
    }
}
.pagebox {
    margin-top: 20px;
    text-align: left;
}
</style>