<template>
    <div>
        <el-dialog
        title="申请加入"
        :close-on-click-modal="false" 
        :visible.sync="dialogVisibleShow">
            <el-form :model="ruleOrgForm" ref="ruleOrgForm" label-width="auto" class="demo-ruleForm">
                <el-form-item>
                    <el-input v-model="ruleOrgForm.name" placeholder="请输入组织名称" size="small" clearable @input="changeIptEvent" >
                        <!-- <i slot="suffix" class="el-input__icon el-icon-search seicons" @click="handleClick"></i> -->
                        <template slot="append">
                            <el-button @click="changeIptEvent">搜索</el-button>
                        </template>
                    </el-input>
                </el-form-item>
            </el-form>
        
            <el-tree
            v-if="ruleOrgForm.name"
            :data="viewer"
            :props="defaultProps"
            :load="loadNode"
            @node-click="checkChangeEvent"
            style="padding-left: 65px;"
            lazy>
            </el-tree>

            <el-form :model="ruleForm" ref="ruleForm" label-width="70px" class="demo-ruleForm">
                <el-form-item label="申请备注">
                    <el-input type="textarea" v-model="ruleForm.description" placeholder="请输入申请备注信息" size="small"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="dialogVisibleShow = false">取 消</el-button>
                <el-button size="small" type="primary" @click="onSubmits">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import { orgAPI } from 'liankong-ui-api'
export default {
    data() {
        return {
            dialogVisibleShow: false,
            viewer: [],
            ruleForm: {
                description: ''
            },
            defaultProps: {
                children: 'groupModelList',
                label: 'name',
                isLeaf: 'organizationId'
            },
            checkChangeTreeInfo: {},
            ruleOrgForm: {
                name: '',
                pageNum: 1,
                pageSize: 999999
            }
        }
    },
    methods: {
        onInit(){
            this.ruleForm.description = '';
            this.ruleOrgForm.name = '';
        },
        getOrgAllList(){
            orgAPI.getPageOrgList(this.ruleOrgForm).then(res=>{
                if(res.code == 0) {
                    this.viewer = res.data;
                }
            })
        },
        changeIptEvent(value) {
            this.getOrgAllList();
        },
        loadNode(node, resolve) {
            if(node.data.organizationId) {
                resolve([]);
            } else if(node.data.organizationId == undefined) {
                orgAPI.groupList({
                    id: node.data.id,
                    pageNum: 1,
                    pageSize: 999999
                }).then(res=>{
                    if(res.code == 0) {
                        resolve(res.data);
                    }
                })
            }
        },
        // tree左侧点击
        checkChangeEvent(data){
            this.checkChangeTreeInfo = data;
        },
        onSubmits(){
            if(this.checkChangeTreeInfo.organizationId) { // 部门
                this.$store.dispatch('newOrg/applyJoinGroup',{
                    id: this.checkChangeTreeInfo.id,
                    description: this.ruleForm.description
                }).then(state=>{
                    if(state.code==0){
                        this.$message.success("已发送申请")
                        this.dialogVisibleShow = false;
                    }else{
                        this.$message.error(state.message);
                        this.dialogVisibleShow = false;
                    }
                }).catch(res=>{
                    this.$message.error(res.message);
                    this.dialogVisibleShow = false;
                })
            } else if(this.checkChangeTreeInfo.organizationId == undefined) { // 组织
                this.$store.dispatch('newOrg/applyJoinOrg',{
                    id: this.checkChangeTreeInfo.id,
                    description: this.ruleForm.description
                }).then(state=>{
                    if(state.code==0){
                        this.$message.success("已发送申请")
                        this.dialogVisibleShow = false;
                    }else{
                        this.$message.error(state.message);
                        this.dialogVisibleShow = false;
                    }
                }).catch(res=>{
                    this.$message.error(res.message);
                    this.dialogVisibleShow = false;
                })
            }
          
        }
    }
}
</script>
<style lang="less" scoped>
/deep/ .demo-ruleForm {
    margin-top: 20px;
}
</style>