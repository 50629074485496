<template>
    <div>
        <el-dialog
        :title="flag ? '修改部门' : '添加部门' "
        :close-on-click-modal="false" 
        :visible.sync="depIsShow">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <el-form-item label="部门名称" prop="name">
                    <el-input v-model="ruleForm.name" placeholder="请输入名称" size="small"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="cancleEvents('ruleForm')">取 消</el-button>
                <el-button size="small" type="primary" @click="onSubmits">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
  export default {
    data() {
      return {
        depIsShow: false,
        ruleForm: {
          name: ''
        },
        rules: {
          name: [
            { required: true, message: '部门名称不能为空', trigger: 'blur' }
          ]
        },
        flag: false,
        infos: {}
      };
    },
    methods: {
        init(isFlag, infos){
            this.flag = isFlag;
            this.infos = infos;
            if(isFlag) {
                this.ruleForm.name = infos.name;
            } else {
                this.ruleForm = {
                    name: ''
                }
            }
        },
        onSubmits(){
             this.$refs['ruleForm'].validate((valid) => {
                if (valid) {
                    if(this.flag) {
                        this.$store.dispatch("newGroup/renameGroup", {
                            vkey:'group', 
                            query:{
                                id: this.infos.id,
                                name: this.ruleForm.name,
                            }          
                        }).then(state =>{
                            if(state.code==0){
                                this.$message.success('修改成功');
                                this.depIsShow = false;
                                // 刷新左侧组织树列表
                                this.$emit('refreshLeftMenuDatas');
                            }else{
                                this.$message.error(state.message)
                            }
                        }).catch(error => {
                            this.$message.error(error.message);
                        })
                    } else {
                        let query = {
                            name: this.ruleForm.name,
                            organizationId: this.infos.organizationId ? this.infos.organizationId :this.infos.id,
                            parentId:this.infos.organizationId ? this.infos.id : 0
                        }
                        this.$store.dispatch("newOrg/createDepartment", query).then(state =>{
                            if(state.code==0){
                                this.$message.success('添加成功');
                                this.depIsShow = false;
                                // 刷新左侧组织树列表
                                this.$emit('refreshLeftMenuDatas')
                            }else{
                                this.$message.error(state.message);
                                this.depIsShow = false;
                            }
                        }).catch(error => {
                            this.$message.error(error.message);
                        })
                    }
                }
             })
        },
        cancleEvents(formName){
            this.$refs[formName].resetFields();
            this.depIsShow = false;
        },
    }
  };
</script>
<style lang="less" scoped>

</style>