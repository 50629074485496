<template>
    <div>
        <el-dialog
        :title="isFlag ? '组织重命名' : '添加组织' "
        :close-on-click-modal="false" 
        :visible.sync="dialogVisible">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <el-form-item label="组织名称" prop="name">
                    <el-input v-model="ruleForm.name" placeholder="请输入名称" size="small"></el-input>
                </el-form-item>
                <el-form-item label="组织结构码" prop="code" v-if="!isFlag">
                    <el-input v-model="ruleForm.code" placeholder="请输入结构码" size="small"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="cancleEvent('ruleForm')">取 消</el-button>
                <el-button size="small" type="primary" @click="onSureEvent">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
  export default {
    data() {
      return {
        dialogVisible: false,
        ruleForm: {
          name: '',
          code: ''
        },
        rules: {
          name: [
            { required: true, message: '不能为空', trigger: 'blur' }
          ],
          code: [
            { required: true, message: '不能为空', trigger: 'blur' }
          ]
        },
        isFlag: false,
        infos: {}
      };
    },
    methods: {
        init(isFlag, infos){
            this.isFlag = isFlag;
            this.infos = infos;
            if(isFlag) {
                this.$store.dispatch("newOrg/orgInfoById", { id: infos.id}).then(res=>{
                    this.ruleForm.name = res.data.name;
                })
            } else {
                this.ruleForm = {
                    name: '',
                    code: ''
                }
            }
        },
        onSureEvent(){
             this.$refs['ruleForm'].validate((valid) => {
                if (valid) {
                    if(this.isFlag) {
                         this.$store.dispatch('newOrg/renameOrganization',{
                            name: this.ruleForm.name,
                            code: this.infos.code,
                            id: this.infos.id
                        }).then(state =>{
                            if(state.code==0){
                                this.$message.success('修改成功');
                                this.dialogVisible = false;
                                // 刷新左侧组织树列表
                                this.$emit('refreshLeftMenuData');
                            }else{
                                this.$message.error(state.message)
                                this.dialogVisible = false;
                            }
                        }).catch(error => {
                            this.$message.error(error.message)
                        })
                    } else {
                        let query = {
                            name: this.ruleForm.name,
                            code: this.ruleForm.code
                        }
                        let pattern = new RegExp("[A-Za-z]+")
                        if(this.ruleForm.code.length < 4 || !pattern.test(this.ruleForm.code)){
                            this.$message.error('请输入不少于4位英文字母的组织结构码!')
                        }else{
                            this.$store.dispatch('newOrg/createOrg', query).then(res=>{
                                if (res.code == 0) {
                                    this.$message.success('创建成功');
                                    this.dialogVisible = false;
                                    // 刷新左侧组织树列表
                                    this.$emit('refreshLeftMenu');
                                } else {
                                    this.$message.error(res.message);
                                    // this.dialogVisible = false;
                                }
                            }).catch(res=>{
                                this.$message.error(res.message);
                            })
                        }
                    }
                }
             })
        },
        cancleEvent(formName){
            this.$refs[formName].resetFields();
            this.dialogVisible = false;
        },
    }
  };
</script>
<style lang="less" scoped>

</style>